
// Do not edit directly
// Generated on Tue, 18 Mar 2025 10:32:28 GMT

$color-collection-boutique: #1f448d;
$color-collection-cycling: #333333;
$color-collection-excellent: #5e2f7e;
$color-collection-fam-fun: #ed2924;
$color-collection-secrets: #00bfd4;
$color-collection-selections: #f66e20;
$color-collection-ski-in-ski-out: #056464;
$color-collection-villages: #147a58;
$color-collection-xperience: #ed2924;
$color-collection-atlantis: #fe6400;
$color-collection-totally: #ff6600;
$color-collection-primavera: #00b49d;
$color-palette-grey-50: #FFFFFF;
$color-palette-grey-100: #FBF9F6;
$color-palette-grey-200: #EDEBE7;
$color-palette-grey-300: #D9D6D1;
$color-palette-grey-400: #B5B0AB;
$color-palette-grey-500: #928D8B;
$color-palette-grey-700: #494141;
$color-palette-grey-800: #362F2F;
$color-palette-grey-900: #000000;
$color-palette-primary-300: #FFCACF;
$color-palette-primary-500: #FF333F;
$color-palette-primary-700: #EA2934;
$color-palette-green-300: #A6E2CF;
$color-palette-green-500: #21B788;
$color-palette-green-700: #198966;
$color-palette-red-300: #FACAC9;
$color-palette-red-500: #ED2924;
$color-palette-red-700: #CE231F;
$color-palette-yellow-300: #FDEFB8;
$color-palette-yellow-500: #F4BE15;
$color-palette-yellow-700: #FF9933;
$color-palette-blue-300: #A7DEF9;
$color-palette-blue-500: #008FCB;
$color-palette-blue-700: #006996;
$color-palette-transparent-white-75: rgba(255, 255, 255, 0.75);
$color-palette-transparent-black-16: rgba(0, 0, 0, 0.16);
$color-palette-transparent-black-25: rgba(0, 0, 0, 0.25);
$color-palette-transparent-black-50: rgba(0, 0, 0, 0.5);
$color-palette-transparent-black-80: rgba(0, 0, 0, 0.80);
$color-palette-transparent-red-20: rgba(255, 51, 63, 0.2);
$color-palette-transparent-sand-50: rgba(231, 224, 215, 0.5);
$color-semantic-bg: #FFFFFF;
$color-semantic-bg-disabled: #EDEBE7;
$color-semantic-bg-error: #ED2924;
$color-semantic-bg-error-hover: #CE231F;
$color-semantic-bg-error-subdued: #FACAC9;
$color-semantic-bg-info: #008FCB;
$color-semantic-bg-info-disabled: #A7DEF9;
$color-semantic-bg-info-subdued: #A7DEF9;
$color-semantic-bg-input-strong: #928D8B;
$color-semantic-bg-input-strong-hover: #494141;
$color-semantic-bg-input-strong-disabled: #EDEBE7;
$color-semantic-bg-inverse: #362F2F;
$color-semantic-bg-inverse-hover: #000000;
$color-semantic-bg-inverse-disabled: #928D8B;
$color-semantic-bg-primary: #FF333F;
$color-semantic-bg-primary-hover: #EA2934;
$color-semantic-bg-primary-disabled: #D9D6D1;
$color-semantic-bg-success: #21B788;
$color-semantic-bg-success-hover: #198966;
$color-semantic-bg-success-disabled: #A6E2CF;
$color-semantic-bg-success-subdued: #A6E2CF;
$color-semantic-bg-warning: #F4BE15;
$color-semantic-bg-warning-subdued: #FDEFB8;
$color-semantic-bg-strong: #E7E0D7;
$color-semantic-bg-strong-hover: #837868;
$color-semantic-bg-strong-disabled: #F3EFEB;
$color-semantic-bg-subdued: #FBF9F6;
$color-semantic-bg-subdued-hover: #F3EFEB;
$color-semantic-border: #D9D6D1;
$color-semantic-border-disabled: #D9D6D1;
$color-semantic-border-hover: #928D8B;
$color-semantic-border-active: #928D8B;
$color-semantic-border-error: #ED2924;
$color-semantic-border-error-hover: #CE231F;
$color-semantic-border-primary: #FF333F;
$color-semantic-border-primary-disabled: #FFCACF;
$color-semantic-border-primary-hover: #EA2934;
$color-semantic-border-strong: #837868;
$color-semantic-border-strong-hover: #63533D;
$color-semantic-border-strong-disabled: #F3EFEB;
$color-semantic-border-strong-active: #362F2F;
$color-semantic-border-subdued: #EDEBE7;
$color-semantic-border-info: #008FCB;
$color-semantic-border-success: #21B788;
$color-semantic-border-success-disabled: #A6E2CF;
$color-semantic-border-success-hover: #198966;
$color-semantic-border-warning: #F4BE15;
$color-semantic-icon: #362F2F;
$color-semantic-icon-disabled: #D9D6D1;
$color-semantic-icon-hover: #000000;
$color-semantic-icon-on-color: #FFFFFF;
$color-semantic-icon-strong: #837868;
$color-semantic-icon-subdued: #928D8B;
$color-semantic-icon-success: #21B788;
$color-semantic-text: #362F2F;
$color-semantic-text-error: #ED2924;
$color-semantic-text-on-color: #FFFFFF;
$color-semantic-text-disabled: #D9D6D1;
$color-semantic-text-placeholder: #928D8B;
$color-semantic-text-primary: #FF333F;
$color-semantic-text-primary-disabled: #FFCACF;
$color-semantic-text-primary-hover: #EA2934;
$color-semantic-text-strong: #837868;
$color-semantic-text-subdued: #928D8B;
$color-semantic-text-subdued-hover: #000000;
$font-family-primary: var(--font-branding, 'Branding Sunweb');
$font-family-secondary: var(--font-general-sans, 'General Sans');
$font-line-height-xs: 1;
$font-line-height-sm: 1.1;
$font-line-height-md: 1.2;
$font-line-height-lg: 1.5;
$font-size-2xs: 11px;
$font-size-xs: 13px;
$font-size-sm: 15px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xl: 24px;
$font-size-2xl: 28px;
$font-size-3xl: 32px;
$font-size-4xl: 40px;
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$typography-heading-2xl-typeface: var(--font-branding, 'Branding Sunweb');
$typography-heading-2xl-size: 40px;
$typography-heading-2xl-line-height: 1;
$typography-heading-2xl-style: normal;
$typography-heading-2xl-weight: 700;
$typography-heading-2xl-letter-spacing: 0;
$typography-heading-xl-typeface: var(--font-branding, 'Branding Sunweb');
$typography-heading-xl-size: 32px;
$typography-heading-xl-line-height: 1;
$typography-heading-xl-style: normal;
$typography-heading-xl-weight: 700;
$typography-heading-xl-letter-spacing: -0.32px;
$typography-heading-lg-typeface: var(--font-branding, 'Branding Sunweb');
$typography-heading-lg-size: 28px;
$typography-heading-lg-line-height: 1;
$typography-heading-lg-style: normal;
$typography-heading-lg-weight: 700;
$typography-heading-lg-letter-spacing: -0.28px;
$typography-heading-md-typeface: var(--font-branding, 'Branding Sunweb');
$typography-heading-md-size: 24px;
$typography-heading-md-line-height: 1.1;
$typography-heading-md-style: normal;
$typography-heading-md-weight: 700;
$typography-heading-md-letter-spacing: -0.24px;
$typography-body-lg-typeface: var(--font-general-sans, 'General Sans');
$typography-body-lg-size: 18px;
$typography-body-lg-line-height: 1.5;
$typography-body-lg-style: normal;
$typography-body-lg-weight: 500;
$typography-body-lg-letter-spacing: 0;
$typography-body-lg-bold-typeface: var(--font-general-sans, 'General Sans');
$typography-body-lg-bold-size: 18px;
$typography-body-lg-bold-line-height: 1.5;
$typography-body-lg-bold-style: normal;
$typography-body-lg-bold-weight: 600;
$typography-body-lg-bold-letter-spacing: 0;
$typography-body-md-typeface: var(--font-general-sans, 'General Sans');
$typography-body-md-size: 15px;
$typography-body-md-line-height: 1.5;
$typography-body-md-style: normal;
$typography-body-md-weight: 500;
$typography-body-md-letter-spacing: 0.3px;
$typography-body-md-bold-typeface: var(--font-general-sans, 'General Sans');
$typography-body-md-bold-size: 15px;
$typography-body-md-bold-line-height: 1.5;
$typography-body-md-bold-style: normal;
$typography-body-md-bold-weight: 600;
$typography-body-md-bold-letter-spacing: 0.3px;
$typography-body-md-caption-typeface: var(--font-general-sans, 'General Sans');
$typography-body-md-caption-size: 15px;
$typography-body-md-caption-line-height: 1.1;
$typography-body-md-caption-style: normal;
$typography-body-md-caption-weight: 600;
$typography-body-md-caption-letter-spacing: 0.3px;
$typography-body-sm-typeface: var(--font-general-sans, 'General Sans');
$typography-body-sm-size: 13px;
$typography-body-sm-line-height: 1.2;
$typography-body-sm-style: normal;
$typography-body-sm-weight: 500;
$typography-body-sm-letter-spacing: 0.26px;
$typography-body-sm-bold-typeface: var(--font-general-sans, 'General Sans');
$typography-body-sm-bold-size: 13px;
$typography-body-sm-bold-line-height: 1.2;
$typography-body-sm-bold-style: normal;
$typography-body-sm-bold-weight: 600;
$typography-body-sm-bold-letter-spacing: 0.26px;
$typography-body-xs-typeface: var(--font-general-sans, 'General Sans');
$typography-body-xs-size: 11px;
$typography-body-xs-line-height: 1.2;
$typography-body-xs-style: normal;
$typography-body-xs-weight: 500;
$typography-body-xs-letter-spacing: 0.33px;
$typography-body-xs-bold-typeface: var(--font-general-sans, 'General Sans');
$typography-body-xs-bold-size: 11px;
$typography-body-xs-bold-line-height: 1.2;
$typography-body-xs-bold-style: normal;
$typography-body-xs-bold-weight: 600;
$typography-body-xs-bold-letter-spacing: 0.33px;
$typography-label-typeface: var(--font-general-sans, 'General Sans');
$typography-label-size: 16px;
$typography-label-line-height: 1.5;
$typography-label-style: normal;
$typography-label-weight: 600;
$typography-label-letter-spacing: 0;
$typography-label-thin-typeface: var(--font-general-sans, 'General Sans');
$typography-label-thin-size: 16px;
$typography-label-thin-line-height: 1.5;
$typography-label-thin-style: normal;
$typography-label-thin-weight: 500;
$typography-label-thin-letter-spacing: 0;
$theme-color-neutral-100: #FBF9F6;
$theme-color-neutral-200: #FAF7F3;
$theme-color-neutral-300: #F3EFEB;
$theme-color-neutral-400: #E7E0D7;
$theme-color-neutral-500: #837868;
$theme-color-neutral-700: #63533D;
$theme-color-neutral-800: #362F2F;
$breakpoint-tablet: 600px;
$breakpoint-desktop-sm: 840px;
$breakpoint-desktop-md: 960px;
$breakpoint-desktop-lg: 1280px;
$breakpoint-desktop-xl: 1440px;
$mq-tablet: (min-width: 600px);
$mq-desktop-sm: (min-width: 840px);
$mq-desktop-md: (min-width: 960px);
$mq-desktop-lg: (min-width: 1280px);
$mq-desktop-xl: (min-width: 1440px);
$radius-none: 0;
$radius-medium: 4px;
$radius-large: 8px;
$radius-extra-large: 16px;
$radius-rounded: 1000px;
$shadow-md-top: 0px -2px 6px 0px rgba(0, 0, 0, 0.08);
$shadow-md-bottom:  0px 2px 6px 0px rgba(0, 0, 0, 0.08);
$shadow-lg-top: 0px -2px 8px 0px rgba(0, 0, 0, 0.16);
$shadow-lg-bottom: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
$shadow-input: 0px 2px 6px 0px rgba(0, 0, 0, 0.08) inset;
$shadow-input-focus-grey: 0px 0px 0px 3px rgba(0, 0, 0, 0.20), 0px 2px 6px 0px rgba(0, 0, 0, 0.08) inset;
$shadow-focus-red: 0px 0px 0px 3px rgba(255, 51, 63, 0.2);
$shadow-focus-grey: 0px 0px 0px 3px rgba(0, 0, 0, 0.25);
$spacing-none: 0;
$spacing-2xs: 4px;
$spacing-xs: 8px;
$spacing-sm: 12px;
$spacing-md: 16px;
$spacing-lg: 24px;
$spacing-xl: 32px;
$spacing-2xl: 40px;
$spacing-3xl: 48px;
$spacing-4xl: 64px;
$spacing-5xl: 80px;
